<!---->
<template>
  <div>
  <div v-if="notShow">无权限</div>
  <div v-else class="main-container" style="background: #fff">
    <el-button
      type="primary"
      @click="addShop"
      size="mini"
      style="margin: 10px; float: right">添加商品
    </el-button>
    <el-form
      :model="form"
      class="form-style"
      size="mini"
      :inline="true">

      <!--输入框-->
      <el-form-item label="商品名称">
        <el-input v-model="form.value" placeholder="请输入商品名称"></el-input>
      </el-form-item>
      <!--下拉列表-->
      <el-form-item label="分类">
        <el-select v-model="form.value1" placeholder="请选择分类">
          <el-option
            v-for="item in option"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>


      <el-form-item>
        <el-button type="primary" @click="getTableList">搜索</el-button>
      </el-form-item>
    </el-form>

    <TableBody ref="tableBody">
      <template>
        <el-table
          ref="table"
          v-loading="tableLoading"
          :data="tableData"
          :header-cell-style="tableConfig.headerCellStyle"
          :size="tableConfig.size"
          :stripe="tableConfig.stripe"
          :border="false">


          <el-table-column label="商品ID" prop="goodsId" align="center"></el-table-column>
          <el-table-column label="商品分类" prop="goodsType" align="center"></el-table-column>
          <el-table-column label="商品名称" prop="goodsName" min-width="200" align="center"></el-table-column>
          <el-table-column label="商品价格" prop="price" align="center"></el-table-column>
          <el-table-column label="添加时间" prop="createTime" align="center"></el-table-column>
          <el-table-column label="操作" prop="" align="center" fixed="right" width="200">
            <template slot-scope="scope">
              <el-button class="button btnGreen" size="mini"  @click="edit(scope.row)">编辑</el-button>
              <el-button class="button" size="mini" type="danger" @click="deleteGood(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>

      <!-- 分页区域 -->
      <div class="serviceManage-page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.size"
          :page-sizes="[10, 20, 30, 40]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>

    </TableBody>
    <!--新增商品-->
    <el-dialog
      title="新增商品"
      :visible.sync="dialogVisible"
      width="70%"
    >
      <el-form class="form-style" ref="form" :model="formAdd" label-width="100px">
        <el-form-item label="商品名称">
          <el-input v-model="formAdd.goodsName"></el-input>
        </el-form-item>

        <el-form-item label="店铺名称">
          <el-input v-model="formAdd.shopName"></el-input>
        </el-form-item>

        <el-form-item label="商品单价">
          <el-input v-model="formAdd.price"></el-input>
        </el-form-item>

        <el-form-item label="商品logo及轮播展示图片">
          <el-upload
            :on-success="uploadSuccess"
            :action="`https://amazonshoplink.icu/api/amazon/image/upload`"
            class="upload-demo"
            drag
            list-type="picture"
            :file-list="fileList"
            name="image"
            multiple="false">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>

          </el-upload>

        </el-form-item>
        <el-form-item label="商品详细内容">
          <VueEditor v-model="formAdd.detail"></VueEditor>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="confirm">确 定</el-button>
  </span>
    </el-dialog>
  </div>
  </div>
</template>

<script>
import {goodsList, addGoods,updateGoods, deleteGoods} from '../../../api/tradApi'
import {VueEditor} from 'vue2-editor'
import TableMixin, {PageModelMixin} from '@/mixins/TableMixin'
import {GetDataMixin, DeleteItemsMixin} from '@/mixins/ActionMixin'
import {baseUrl} from '../../../api/url'

export default {
  name: 'shopList',
  mixins: [TableMixin, PageModelMixin, GetDataMixin, DeleteItemsMixin],
  data() {
    return {
      baseUrl,
      notShow: false,
      type: '', // 新增 or 编辑
      dialogVisible: false,
      tableLoading: false,
      tableData: [],
      form: {
        value: ''
      },
      pagination: {
        page: 1,
        size: 20
      },
      formAdd: {
        goodsName: '',
        shopName: '',
        price: '',
        detail: '',
        goodsImg: ''
      },
      fileList: [],
      total: ''

    }
  },
  components: {VueEditor},

  mounted() {
    this.notShow = window.localStorage.isAdmin != '1'
    this.getTableList()
  },
  methods: {
    getTableList() {
      const data = {...this.pagination}
      goodsList(data).then(res => {
        if (res.data.status === 0) {
          this.tableData = res.data.result
          this.total = res.data.total
        }
      })
    },
    addShop() {
      this.dialogVisible = true
      this.type = 'add'
      this.formAdd = {}
      this.fileList = []
    },
    //新增商品
    addSubmit() {
      addGoods({...this.formAdd}).then(res => {
        if (res.data.status === 0) {
          this.dialogVisible = false
          this.$message({
            message: '添加成功',
            type: 'success'
          })
          this.getTableList()
        } else {
          this.$message({
            message: '失败',
            type: 'error'
          })
        }
      })
    },
    edit(row) {
      this.type = 'edit'
      this.formAdd = JSON.parse(JSON.stringify(row))
      this.formAdd.goodsId = row.goodsId
      this.fileList = [{
        url: this.formAdd.goodsImg.indexOf('http') !== -1 ? this.formAdd.goodsImg : ('https://amazonshoplink.icu/' + this.formAdd.goodsImg)
      }]
      this.dialogVisible = true
    },

    //编辑商品
    editSubmit() {
      updateGoods({...this.formAdd}).then(res => {
        if (res.data.status === 0) {
          this.dialogVisible = false
          this.$message({
            message: '更新成功',
            type: 'success'
          })
          this.getTableList()
        } else {
          this.$message({
            message: '失败',
            type: 'error'
          })
        }
      })
    },
    // 添加商品点击确定
    confirm() {
      if (this.type === 'add') {
        this.addSubmit()
      } else {
        this.editSubmit()
      }

    },

    deleteGood(row) {
      deleteGoods({goodsId: row.goodsId}).then(res => {
        if (res.data.status === 0) {
          this.$message({
            message: '删除成功',
            type: 'success'
          })
          this.getTableList()
        } else {
          this.$message({
            message: '失败',
            type: 'error'
          })
        }
      })
    },
    handleSizeChange(size) {
      this.pagination.size = size
      this.pagination.page = 1
      this.getTableList()
    },
    handleCurrentChange(page) {
      this.pagination.page = page
      this.getTableList()
    },

    uploadSuccess(response, file, fileList) {
      console.log(file)
      this.formAdd.goodsImg = response.result.url
    }
  }

}
</script>

<style lang="scss" scoped>
.btnGreen {
  background: #009688;
  color: white
}
</style>

